import createSvgIcon from '../Utils/CreateSvgIcon';

export const ThinSearch = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 0C13.9706 0 18 4.02944 18 9C18 11.1927 17.2159 13.2022 15.9127 14.7635L19.9264 18.8539L18.7843 19.9745L14.7829 15.8965C13.2189 17.2094 11.2018 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.6C4.91309 1.6 1.6 4.91309 1.6 9C1.6 13.0869 4.91309 16.4 9 16.4C13.0869 16.4 16.4 13.0869 16.4 9C16.4 4.91309 13.0869 1.6 9 1.6Z"
  />,
  'ThinSearch',
  '0 0 20 20',
);
