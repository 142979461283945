'use client';
import { Fragment, useEffect, useRef } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { usePathname } from 'next/navigation';

import { closeModalAtom, isModalOpenAtom, modalDataAtom } from '../../Atoms/Modal/Modal.atom';

import { useLockBodyScroll } from '../../Utils/UseBodyLockOnScroll';

import { ModalHeader } from './ModalHeader';

import styles from './Modal.module.css';
import { isDebugTranslationOnClient } from '@intl/IsDebugTranslationOnClient';
import { Close } from '@gds/Icons/Paths/Close';

export const Modal = () => {
  const isFirstRender = useRef(true);
  const modalProps = useAtomValue(modalDataAtom);
  const isModalOpen = useAtomValue(isModalOpenAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pathname = usePathname();

  const {
    content,
    modalAction,
    header,
    onClose,
    children,
    maxWidth,
    modalBodyClassName = '',
    footerClassName = '',
    modalClassName = '',
    screenWrapperClassName = '',
    modalContentClassName,
    centered = true,
    customCloseIcon = <Close />,
    enableHeaderClose = false,
    customScrollClassName,
  } = modalProps;

  useLockBodyScroll(isModalOpen, customScrollClassName);

  const ContentWrapper = modalContentClassName ? 'div' : Fragment;
  const wrapperClassName = modalContentClassName ? modalContentClassName : undefined;

  const close = () => {
    onClose?.();
    closeModal();
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    close();
  }, [pathname]);
  return (
    <div
      className={`${screenWrapperClassName} ${styles.screenWrapper}`}
      onClick={close}
      data-test-id="modal"
      data-is-active={isModalOpen}
      data-is-debug-on={isDebugTranslationOnClient()}
    >
      <div
        className={`${modalClassName} ${styles.modal} ${centered && styles.centered}`}
        style={{ ...((maxWidth && { maxWidth }) || {}) }}
        onClick={e => (onClose ? e.stopPropagation() : undefined)}
      >
        {header && (
          <ModalHeader customCloseIcon={customCloseIcon} enableHeaderClose={enableHeaderClose} />
        )}

        <ContentWrapper {...(wrapperClassName && { className: wrapperClassName })}>
          <div className={`${modalBodyClassName} ${styles.modalBody}`}>{content || children}</div>
          {modalAction && (
            <div className={`${footerClassName} ${styles.modalFooter}`}>{modalAction}</div>
          )}
        </ContentWrapper>
      </div>
    </div>
  );
};
