import { DEBUG_TRANSLATION } from '@cookies/Constants/Cookie.constants';
import { isProd } from '@core/Utils/App/IsProd';
import { useSearchParams } from 'next/navigation';

export const isDebugTranslationOnClient = () => {
  const searchParams = useSearchParams();
  const debugTranslation = searchParams.get(DEBUG_TRANSLATION);
  if (debugTranslation) {
    return (!isProd && debugTranslation) === 'on';
  }
  return false;
};
