'use client';

import { DEBUG_TRANSLATION } from '@cookies/Constants/Cookie.constants';
import { isWindowDefined } from '@core/Constants/Window';
import { isProd } from '@core/Utils/App/IsProd';
import { useSearchParams } from 'next/navigation';

import PhraseAppInContextScript from './PhraseAppInContextScript';

const PhraseAppInContextWrapper = () => {
  const searchParams = useSearchParams();

  const search = searchParams.get(DEBUG_TRANSLATION);

  if (!isWindowDefined || isProd || search !== 'on') return null;

  return <PhraseAppInContextScript />;
};

export default PhraseAppInContextWrapper;
