'use client';

import { useIsomorphicLayoutEffect } from './UseIsomorphicLayoutEffect';

export const useLockBodyScroll = (
  isModalOpen = true,
  customScrollClassName?: string | undefined,
) => {
  useIsomorphicLayoutEffect(() => {
    const body = document.body;

    body.classList.toggle(customScrollClassName ?? 'lockScroll', isModalOpen);

    return () => {
      body.classList.remove(customScrollClassName ?? 'lockScroll');
    };
  }, [isModalOpen, customScrollClassName]);
};
