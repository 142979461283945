'use client';

import { FC, useState } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { LanguageDropdown } from '@core/LanguageDropdown/LanguageDropdown';
import { ChevronDown } from '@gds/Icons/Paths/ChevronDown';
import useBreakpoint from '@gds/Pagination/Hooks/UseBreakpoint.hook';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { useIsExperimentEnabled } from '@growthBookExperimentation/Hooks/useIsExperimetEnabled';
import { useAtomValue } from 'jotai';

import { FooterGroup } from '../Footer.entity';
import {
  TrackedFooterLink,
  itemOnClick,
  trackEvent,
  trackingFunction,
} from '../TrackedFooterLink/TrackedFooterLink';

import styles from './FooterLinks.module.css';

export const FooterLinks: FC<{
  columns: FooterGroup[] | undefined;
  locale: Locale;
  strings: Record<string, string>;
}> = ({ columns, locale, strings }) => {
  const [expandedGroup, setExpandedGroup] = useState<Record<string, boolean>>({
    'group0-0': true,
  });
  const {
    breakpoints: { isMobile, isTablet },
  } = useBreakpoint();

  const isPrimaryRedesignExpEnabled = useIsExperimentEnabled('navigation-redesign-experiment', [
    '1',
    '2',
  ]);
  const { showDeFeatures: shouldShowDeLang } = useAtomValue(flagsAtom);

  const toggleMenu = (group: string, heading: string) => {
    itemOnClick({
      fn: trackingFunction,
      obj: { label: heading },
    });
    setExpandedGroup(expandedGroup => ({
      ...expandedGroup,
      [group]: !expandedGroup[group],
    }));
  };

  const totalColumns = columns?.length || 0;

  return (
    <>
      {columns?.map(({ footerLinksGroup }, columnIndex) => (
        <div className={styles.groups} key={columnIndex}>
          {footerLinksGroup?.map(({ heading, footerLinkItems }, groupIndex) => (
            <div className={styles.group} key={groupIndex}>
              <button
                data-expanded={expandedGroup[`group${columnIndex}-${groupIndex}`] ?? false}
                onClick={() => toggleMenu(`group${columnIndex}-${groupIndex}`, heading)}
                role="button"
              >
                <Typography Component="span" variant="body2">
                  {heading}
                </Typography>
                <ChevronDown fontSize={16} />
              </button>
              <ul
                data-expanded={expandedGroup[`group${columnIndex}-${groupIndex}`] ?? false}
                className={styles.menuList}
                data-test-id="footer-group-links"
              >
                {footerLinkItems?.map(({ label, href, footerLinkTarget }, index) => (
                  <li className={styles.menuListItem} key={index}>
                    {href ? (
                      <TrackedFooterLink
                        prefetch={false}
                        href={href}
                        trackingObj={{ category: 'footer_navigation', label, href }}
                        {...(footerLinkTarget?.length && { target: '_blank' })}
                      >
                        <Typography Component="span" variant="body4">
                          {label}
                        </Typography>
                      </TrackedFooterLink>
                    ) : (
                      <Typography Component="span" variant="body4">
                        {label}
                      </Typography>
                    )}
                  </li>
                ))}

                {/* Append Language Selector only in the last column */}
                {isPrimaryRedesignExpEnabled &&
                  columnIndex === totalColumns - 1 &&
                  groupIndex === footerLinksGroup.length - 1 && (
                    <li className={styles.langSelector} key="langSelector">
                      <Typography Component="span" variant="body2">
                        {strings.selectCountry}
                      </Typography>
                      <LanguageDropdown
                        className={styles.languageDropdown}
                        locale={locale}
                        strings={{
                          englishLabel: strings.englishLabel,
                          frenchLabel: strings.frenchLabel,
                          germanLabel: strings.germanLabel,
                        }}
                        isMenuPositionedAbove={
                          (shouldShowDeLang && totalColumns > 3 && isTablet) ||
                          (isMobile && shouldShowDeLang)
                        }
                        trackingFunc={obj =>
                          trackEvent({
                            fn: trackingFunction,
                            obj: { ...obj },
                          })
                        }
                      />
                    </li>
                  )}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
