import { useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { toastManagerAtom } from '@core/Components/Toast/ToastManager';
import { fetchJsonWithHeaders } from '@core/Utils/Fetch/FetchJsonWithHeaders';
import { toSentenceCase } from '@core/Utils/ToSentenceCase';
import { Warning } from '@gds/Icons/Paths/Warning';

import { latestNlsQueryAtom } from '../LatestNlsQuery.atom';
import { naturalLanguageSearchQueryAtom } from '../NaturalLanguageSearchQuery.atom';
import { nlsToggleAtom } from '../NaturalLanguageSearchToggle.atom';
import { getPlpPath } from '../PrepPlpFilters';
import { useNlsTracking } from '../UseNlsTracking';
import { isNlsLoadingAtom } from '../IsNlsLoading.atom';

export const useNaturalLanguageSearch = (
  validateUrl: (url: string) => boolean,
  strings: Record<string, string>,
) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { push } = useRouter();
  const setLatestNlsQuery = useSetAtom(latestNlsQueryAtom);
  const [query, setQuery] = useAtom(naturalLanguageSearchQueryAtom);
  const [isVisible, setIsVisible] = useAtom(nlsToggleAtom);
  const setToast = useSetAtom(toastManagerAtom);
  const postcode = useAtomValue(postcodeAtom);
  const locale = useAtomValue(localeAtom);
  const [isLoading, setIsLoading] = useAtom(isNlsLoadingAtom);
  const [latestUrl, setLatestUrl] = useState<string | null>(null);
  const { trackNlsResult } = useNlsTracking();

  useEffect(() => {
    let urlClone = new URL(`${location.origin}${pathname}?${searchParams.toString()}`);
    urlClone.searchParams.delete('postcode');
    urlClone.searchParams.delete('lat');
    urlClone.searchParams.delete('lon');
    urlClone.searchParams.sort();

    const newUrl = urlClone.pathname + urlClone.search;
    setLatestNlsQuery(latestUrl === newUrl ? query : null);
  }, [searchParams, pathname]);

  const handleSearch = async (searchQuery: string) => {
    if (!searchQuery) return;

    setIsLoading(true);
    setQuery(searchQuery);

    try {
      const [newUrl, headers] = (await fetchJsonWithHeaders<[string, any]>('/api/nls', {
        method: 'POST',
        body: JSON.stringify({ query: searchQuery, locale }),
      })) as [string, Headers];

      const removedModels = headers?.get?.('removed-models');

      if (removedModels && !removedModels.includes(',')) {
        setToast({
          children: `${strings.nlsCantFind} ${toSentenceCase(removedModels)}`,
          variant: 'dark',
          icon: <Warning />,
        });
      }

      if (newUrl === '/autos' || newUrl === '/autos=' || newUrl === '/autos?') {
        setIsLoading(false);
        setIsVisible(false);
        setToast({ children: strings.nlsOops, variant: 'dark', icon: <Warning /> });
        return;
      }

      if (newUrl?.includes?.('/autos')) {
        const path = getPlpPath(locale, newUrl, validateUrl);
        let url = new URL(`${location.origin}${path}`);

        if (postcode && !url.searchParams.has('postcode')) {
          url.searchParams.set('postcode', postcode[locale]);
        }

        trackNlsResult(searchQuery, url.pathname + url.search);

        url.searchParams.sort();

        push(url.pathname + url.search);
        setIsLoading(false);
        setIsVisible(false);

        url.searchParams.delete('postcode');
        url.searchParams.delete('lat');
        url.searchParams.delete('lon');
        url.searchParams.sort();
        setLatestUrl(url.pathname + url.search);
      }
    } catch (error) {
      console.error({ error });
      setIsLoading(false);
      setIsVisible(false);
      setToast({ children: strings.nlsError, variant: 'dark', icon: <Warning /> });
    }
  };

  return {
    query,
    setQuery,
    isVisible,
    setIsVisible,
    isLoading,
    handleSearch,
  };
};
