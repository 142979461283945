import { phraseWebAppProjectId } from '@core/Environment/Variables';
import Script from 'next/script';

const PhraseAppInContextScript = (): JSX.Element => {
  return (
    <>
      <Script
        id="phraseapp-in-context-script"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: `
          window.PHRASEAPP_CONFIG = {
            projectId: "${phraseWebAppProjectId}",
            autoLowercase: false,
          };
          (function() {
            var phraseapp = document.createElement('script');
            phraseapp.type = 'text/javascript';
            phraseapp.id='phraseapp-in-context-editor'; phraseapp.async = true;
            phraseapp.src = ['https://', 'phraseapp.com/assets/in-context-editor/2.0/app.js?', new Date().getTime()].join('');
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(phraseapp, s);
          })();
        `,
        }}
      />
    </>
  );
};

export default PhraseAppInContextScript;
