import {
  FilterKey,
  MultiSelectFilterKey,
  FilterParams,
  SingleValueFilterKey,
} from '../../../Entities/Search/QueryParams.entity';

import { MULTISELECT_FILTERS_KEY } from '../Filters.constants';

export const mergeSlugAndSearchParams = (slugParams: FilterParams, searchParams: FilterParams) => {
  const mergedParams = { ...slugParams };

  //slugParams take preference
  Object.keys(searchParams).forEach(key => {
    const typedKey = key as FilterKey;
    const value = searchParams[typedKey];
    const isMultiSelectFltr = MULTISELECT_FILTERS_KEY.includes(key as MultiSelectFilterKey);

    if (mergedParams.hasOwnProperty(key) && isMultiSelectFltr) {
      const currentVal = Array.isArray(mergedParams[typedKey])
        ? mergedParams[typedKey]
        : [mergedParams[typedKey]];
      const concatVal = value ? (currentVal as string[]).concat(value) : (currentVal as string[]);
      const uniqVals = concatVal.filter((val, i, self) => self.indexOf(val) === i);

      mergedParams[typedKey as MultiSelectFilterKey] = [...uniqVals];
    } else if (!mergedParams.hasOwnProperty(key)) {
      mergedParams[typedKey as SingleValueFilterKey] = value as string;
    }
  });

  return mergedParams;
};
