'use client';

import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { AccModalId } from '../Entities/AccModalId';
import { FormId } from '../Entities/FormId';

export const activeAccModalAtom = atom<AccModalId | null>(null);

export const accFormValidationAtom = atomFamily((_formConfigId: AccModalId | FormId) =>
  atom(null as null | (() => Promise<void | false>)),
);

export const accModalAtomFamily = atomFamily((modalId: AccModalId) =>
  atom(
    get => get(activeAccModalAtom) === modalId,
    (get, set, shouldOpen?: boolean) => {
      const activeModalId = get(activeAccModalAtom);

      if (typeof shouldOpen === 'boolean') {
        set(activeAccModalAtom, shouldOpen ? modalId : null);
        return;
      }

      if (activeModalId === modalId) {
        set(activeAccModalAtom, null);
        return;
      }

      set(activeAccModalAtom, modalId);
    },
  ),
);
