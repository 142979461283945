import { Filter } from '../../Entities/Search/SearchFilters/Filter.entity';

function getRangeVal(rangeFltr: Filter, partnerFltr: Filter | undefined) {
  const fullRangeVal = rangeFltr.id.includes('__gte')
    ? `${rangeFltr.value}_${partnerFltr?.value}`
    : `${partnerFltr?.value}_${rangeFltr.value}`;

  return partnerFltr && rangeFltr.value !== partnerFltr?.value ? fullRangeVal : rangeFltr.value;
}

export { getRangeVal };
