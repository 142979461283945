import { Locale } from '@core/Entities/Locale/Locale.entity';
import { filtersSlugToObject } from '@core/Services/Search/Utils/FiltersSlugToObject';
import { mergeSlugAndSearchParams } from '@core/Services/Search/Utils/MergeSlugAndSearchParams';

export const getPlpPath = (locale: Locale, newUrl: string, validateUrl: any) => {
  const url = new URL(`${location.origin}${newUrl}`);

  const searchParams = urlParamsToRecord(url.searchParams);

  const filtersSlug = url.pathname.split('/').slice(1);

  const slugParams = filtersSlugToObject(filtersSlug);

  const combinedParams = mergeSlugAndSearchParams(slugParams, searchParams ?? {});

  const { alternativePath } = validateUrl(locale, [], searchParams, combinedParams, 'autos');

  return alternativePath;
};

function urlParamsToRecord(searchParams: URLSearchParams) {
  const result: any = {};
  // @ts-expect-error - needs downlevelIteration flag
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key);
    result[key] = values.length > 1 ? values : values[0];
  }
  return result;
}
