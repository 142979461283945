import { createJSONStorage } from 'jotai/utils';
import { SyncStringStorage } from 'jotai/vanilla/utils/atomWithStorage';

import Cookies from 'js-cookie';

export const CookieStorage: SyncStringStorage = {
  getItem: key => {
    const cookieValue = key && Cookies.get(key);
    return cookieValue ? cookieValue : null;
  },
  setItem: (key, value) => {
    if (value === null || value === undefined) {
      Cookies.remove(key);
    } else {
      Cookies.set(key, value);
    }
  },
  removeItem: key => {
    Cookies.remove(key);
  },
};

/**
 * For handling storing of more complex data where you would use JSON.parse or JSON.stringify
 * @param initialValue
 * @returns
 */
export function createCookieStorage<T>(initialValue: T) {
  return createJSONStorage<T>(() => ({
    ...CookieStorage,
  }));
}
