import { AllHTMLAttributes } from 'react';
import styles from './Styles/RacLogo.module.css';

type Usage = 'dark_bg' | 'light_bg';
export type SvgIconProps = Usage & AllHTMLAttributes<HTMLOrSVGElement>;

export const RacLogo = ({ usage = 'light_bg' as Usage, ...props }) => {
  return (
    <svg
      className={styles.logo}
      viewBox="0 0 87 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6423 8.59975H6.82219C6.59204 8.59975 6.53947 8.70483 6.53947 8.80631C6.53947 8.88419 6.59021 8.9603 6.64095 8.9875L15.7825 14.9091L14.342 17.1759L5.79295 11.537C4.7112 10.8158 3.88673 10.1472 3.88673 8.75557C3.88673 7.18454 5.02106 6.10279 7.08126 6.10279H12.6169C13.4668 6.10279 13.4668 5.40883 13.4668 5.14971V3.42467C13.4668 3.16737 13.4668 2.47156 12.6169 2.47156H3.50259C2.65276 2.47156 2.65276 3.16737 2.65276 3.42467V17.1759H0V3.55151C0 0.822647 2.0095 0 3.50259 0H12.6949C14.1879 0 16.0688 0.695804 16.0688 3.42467V5.12434C16.067 7.85501 14.1372 8.59975 12.6423 8.59975ZM30.0791 17.1759H20.9902C19.4952 17.1759 17.5401 16.404 17.5401 13.6733V9.52746C17.5401 6.79862 19.5496 5.97416 21.0427 5.97416H30.8529V3.55151C30.8529 3.29602 30.8529 2.60021 30.0049 2.60021H18.8538V0H30.1082C31.603 0 33.5582 0.771908 33.5582 3.50078V13.6461C33.5564 16.3768 31.5741 17.1759 30.0791 17.1759ZM30.851 8.54901H21.0155C20.1657 8.54901 20.1657 9.24297 20.1657 9.50209V13.648C20.1657 13.9053 20.1657 14.5993 21.0155 14.5993H30.003C30.8511 14.5993 30.851 13.9053 30.851 13.648V8.54901ZM39.2605 17.1759C37.7674 17.1759 35.8105 16.404 35.8105 13.6733V3.55151C35.8105 0.824458 37.8164 0 39.3131 0H51.3649V2.60021H39.4164C38.5665 2.60021 38.5665 3.29602 38.5665 3.55151V13.6208C38.5665 13.8781 38.5665 14.5739 39.4164 14.5739H51.3649V17.1759H39.2605Z"
        fill="#F95108"
      />
      <path
        d="M58 11.4513C58 7.93419 60.2686 5.7 63.1098 5.7C64.4888 5.7 65.7662 6.26716 66.6052 7.10067C66.8244 7.31811 66.9078 7.48482 66.9078 7.71675C66.9078 8.03385 66.6215 8.31652 66.2699 8.31652C66.0688 8.31652 65.9003 8.21686 65.7662 8.08278C65.0106 7.29999 64.1028 6.91585 63.1098 6.91585C60.959 6.91585 59.4297 8.68255 59.4297 11.4495C59.4297 14.2327 60.9427 15.9831 63.1098 15.9831C64.3039 15.9831 65.1936 15.4504 65.7318 14.8995C66.0181 14.6168 66.1522 14.5335 66.4041 14.5335C66.7229 14.5335 66.9929 14.8343 66.9929 15.117C66.9929 15.3507 66.9096 15.5011 66.8081 15.6008C65.7988 16.601 64.4888 17.2008 63.1098 17.2008C60.1182 17.2008 58 14.8343 58 11.4513Z"
        className={styles[usage]}
      />
      <path
        d="M67.8573 13.0513C67.8573 10.5671 69.3866 8.90003 71.4034 8.90003C72.4797 8.90003 73.1682 9.24974 73.7064 9.8169V9.70093C73.7064 9.35122 73.9746 9.08485 74.3442 9.08485C74.6975 9.08485 74.9657 9.35122 74.9657 9.70093V16.3854C74.9657 16.7351 74.6975 17.0014 74.3261 17.0014C73.9728 17.0014 73.7046 16.7351 73.7046 16.3854V16.2513C73.1501 16.851 72.3438 17.2008 71.4015 17.2008C69.2851 17.2008 67.8573 15.6008 67.8573 13.0513ZM73.7064 13.8341V11.9496C73.7064 10.7501 72.7986 9.98179 71.5556 9.98179C70.0425 9.98179 69.1184 11.2991 69.1184 13.0495C69.1184 14.8162 70.0425 16.1335 71.5556 16.1335C72.7823 16.1353 73.7064 15.3018 73.7064 13.8341Z"
        className={styles[usage]}
      />
      <path
        d="M76.8484 16.3854V9.70093C76.8484 9.35122 77.1166 9.08485 77.4862 9.08485C77.8395 9.08485 78.1077 9.35122 78.1077 9.70093V9.95099C78.6114 9.38383 79.3344 8.90003 80.0918 8.90003C80.4778 8.90003 80.7297 9.09935 80.7297 9.43275C80.7297 9.78247 80.5793 9.9836 80.0574 9.9836C79.0826 9.9836 78.5281 10.5671 78.1077 11.3679V16.3854C78.1077 16.7351 77.8395 17.0014 77.4862 17.0014C77.1184 17.0014 76.8484 16.7351 76.8484 16.3854Z"
        className={styles[usage]}
      />
      <path
        d="M81.4345 16.5521C81.266 16.418 81.199 16.2857 81.199 16.0683C81.199 15.7675 81.4183 15.5174 81.7027 15.5174C81.8531 15.5174 82.0054 15.5682 82.1739 15.6678C82.5272 15.8672 83.098 16.1172 83.9387 16.1172C85.1655 16.1172 85.7362 15.6497 85.7362 14.8343C85.7362 14.0334 85.0133 13.767 83.888 13.45C82.7954 13.1328 81.2823 12.6998 81.2823 11.0835C81.2823 9.8169 82.3587 8.90003 84.1054 8.90003C85.0133 8.90003 85.6348 9.05042 86.1385 9.3494C86.3904 9.4998 86.5081 9.68281 86.5081 9.88213C86.5081 10.2155 86.3396 10.3985 86.037 10.3985C85.8866 10.3985 85.7508 10.3659 85.584 10.2989C85.1637 10.1159 84.6925 9.98179 84.0892 9.98179C83.0472 9.98179 82.4584 10.5145 82.4584 11.0817C82.4584 11.7159 82.8116 11.9315 84.2577 12.3483C86.1566 12.9154 86.914 13.3648 86.914 14.8651C86.8977 16.2658 85.7199 17.199 83.9224 17.199C82.7446 17.2008 81.9727 16.9689 81.4345 16.5521Z"
        className={styles[usage]}
      />
    </svg>
  );
};
