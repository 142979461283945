import { useAtomValue } from 'jotai';
import { experimentsAtom } from '../Atoms/Experiments.atom';
import { FlagKey } from '../Entities/FlagKeys.entities';

export const useIsExperimentEnabled = (
  flagKey: FlagKey,
  variationIds: Array<'0' | '1' | '2' | '3'> | '0' | '1' | '2' | '3',
) => {
  const experiments = useAtomValue(experimentsAtom);

  const variationIdList = Array.isArray(variationIds) ? variationIds : [variationIds];

  return experiments.some(
    exp =>
      exp.experimentId === flagKey &&
      variationIdList.includes(exp.variationId as '0' | '1' | '2' | '3'),
  );
};
