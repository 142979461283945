export function toSentenceCase(input: string): string {
  return input
    .split(',')
    .map(modelName =>
      modelName
        .trim()
        .split(/[-_]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '),
    )
    .join(', ');
}
