import React, { ReactElement } from 'react';
import styles from './Toast.module.css';
import { Error, Tick, Warning } from '@gds/Icons';

type ToastVariant = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'dark';
type ToastIconName = 'success' | 'error' | 'warning';
type ToastIcon = 'success' | 'error' | 'warning' | ReactElement;

export type ToastProps = {
  id?: string;
  children?: React.ReactNode;
  variant?: ToastVariant;
  icon?: ToastIcon;
  disabled?: boolean;
  inactive?: boolean;
};

const Icons: Record<ToastIconName, React.ComponentType> = {
  success: Tick,
  warning: Warning,
  error: Error,
};

export const Toast = ({
  children,
  variant = 'primary',
  icon,
  disabled = false,
  inactive,
}: ToastProps) => {
  const getIcon = () => {
    if (typeof icon === 'string') {
      const IconComponent = Icons[icon as ToastIconName];
      return IconComponent ? <IconComponent /> : null;
    }
    return icon;
  };
  return (
    <div className={styles.wrapper} data-is-open={!!children} data-is-inactive={inactive}>
      <div className={styles.inner}>
        <div className={styles.toast} data-variant={variant} data-disabled={disabled}>
          {icon && <div className={styles.icon}>{getIcon()}</div>}
          <span className={styles.message}>{children} &nbsp;</span>
        </div>
      </div>
    </div>
  );
};
