import {
  MultiSelectFilterKey,
  FilterParams,
  RangeFilterKey,
  SingleValueFilterKey,
  SlugParamKey,
} from '../../../Entities/Search/QueryParams.entity';

import { MULTISELECT_FILTERS_KEY } from '../Filters.constants';
import { SLUG_PARAM_KEYS, slugParamMapping } from '../Search.constants';

export const filtersSlugToObject = (slug?: string[]) => {
  const output: FilterParams = {};
  const keys: string[] = [];
  const values: string[] = [];
  if (!slug?.length) return {};

  //a valid slug can translate to key value pairs
  slug.forEach((slugPart, i) => {
    if (i % 2 === 0) {
      keys.push(slugPart);
    } else {
      values.push(slugPart);
    }
  });

  keys.forEach((key, i) => {
    if (SLUG_PARAM_KEYS.includes(key as SlugParamKey) && !!values[i]) {
      const filterKeys = slugParamMapping[key as SlugParamKey];
      //is range filter
      if (filterKeys.length === 2) {
        const range = values[i].split('_');
        output[filterKeys[0] as RangeFilterKey] = range[0];
        output[filterKeys[1] as RangeFilterKey] = range[1] ? range[1] : range[0];

        //is multiselect filter
      } else if (MULTISELECT_FILTERS_KEY.includes(filterKeys[0] as MultiSelectFilterKey)) {
        output[filterKeys[0] as MultiSelectFilterKey] = [values[i]];

        //is single value filter
      } else {
        output[filterKeys[0] as SingleValueFilterKey] = values[i] as 'new' | 'used' | undefined;
      }
    }
  });

  //if there is a model in the slug filters (meaning there is only 1 make and 1 model)
  //we strip the make id from the model id for readability
  if (output.make && output.model?.length === 1) {
    const modelName = output.model[0].replace('-', '_');
    output.model[0] = `${output.make}-${modelName}`;

    if (output['variant-type']?.length === 1) {
      output['variant-type'][0] = `${output.make}-${modelName}-${output['variant-type'][0]}`;
    }
  }

  return output;
};
