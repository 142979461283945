import createSvgIcon from '../Utils/CreateSvgIcon';

export const Spinner = createSvgIcon(
  <circle cx="12" cy="12" r="10" fill="none" strokeWidth="2" strokeDasharray="56.5 6.5">
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="0 12 12"
      to="360 12 12"
      dur="1s"
      repeatCount="indefinite"
    />
  </circle>,
  'Spinner',
  '0 0 24 24',
);
