import { useCallback } from 'react';

import { nlsToEvent } from '@tracking/Schemas/Nls/NlsToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { useAtomValue } from 'jotai';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';

export const useNlsTracking = () => {
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);

  const userData = { userId, values: { ...formState } };
  const userEvent = userContextToEvent(userData);

  const trackNlsQuery = useCallback(
    (query: string) => {
      const event = nlsToEvent({ query });
      trackCustomEvent({ event, context: [userEvent] });
    },
    [userId],
  );

  const trackNlsResult = useCallback(
    (query: string, resultUrl: string) => {
      const event = nlsToEvent({ query, resultUrl });
      trackCustomEvent({ event, context: [userEvent] });
    },
    [userId],
  );

  return { trackNlsQuery, trackNlsResult };
};
