import { Locale } from '../../Entities/Locale/Locale.entity';
import { SLUG_PARAM_KEYS, slugParamMapping } from '../../Services/Search/Search.constants';
import { getRangeFilterPartnerId } from '../../Services/Search/Utils/GetRangeFilterPartnerId';
import { getSlugParamFromFilter } from '../../Services/Search/Utils/GetSlugParamFromFilter';
import { getSlugParamValueFromFilter } from '../../Services/Search/Utils/GetSlugParamValueFromFilter';

import { createQueryStrFromFilters } from './CreateQueryStrFromFilters';
import { Filter } from '../../Entities/Search/SearchFilters/Filter.entity';
import { getRangeVal } from './GetRangeVal';

type FilterPlacement = 'path' | 'query' | undefined;

function createPathFromFilters(paramsArray: Filter[], locale: Locale, root = 'autos') {
  const rangeFltrs: Filter[] = [];
  const forPath: Filter[] = [];
  const forQuery: Filter[] = [];
  let makePosition: FilterPlacement = undefined;
  let modelPosition: FilterPlacement = undefined;
  let variantPosition: FilterPlacement = undefined;
  let isValidLocationPlp = false;
  let isValidDealerShipPlp = false;
  let PAGE_PATH;

  if (root === 'locations') {
    paramsArray.forEach(filter => {
      if (filter.id === 'region' || filter.id === 'area') {
        isValidLocationPlp = true;
        return;
      }
    });
  }

  if (root === 'dealerships') {
    paramsArray.forEach(filter => {
      if (filter.id === 'dealership-id') {
        isValidDealerShipPlp = true;
        return;
      }
    });
  }

  if (isValidLocationPlp) {
    PAGE_PATH = '/locations';
  } else if (isValidDealerShipPlp) {
    PAGE_PATH = '/dealerships';
  } else if (root === 'dealerships' || root === 'locations') {
    PAGE_PATH = `/autos`;
  } else {
    PAGE_PATH = `/${root}`;
  }
  const newPath = [`/${locale}`, PAGE_PATH];

  // split into Path or Query
  paramsArray.forEach(filter => {
    if (Array.isArray(filter.value) && filter.value?.length > 1) {
      forQuery.push(filter);
      if (filter.id === 'make') makePosition = 'query';
      if (filter.id === 'model') modelPosition = 'query';
      if (filter.id === 'variant-type') variantPosition = 'query';
    } else if (
      //price is the only range filters that has a separate slug param for min and max
      !filter.id.includes('price') &&
      (filter.id.includes('__gte') || filter.id.includes('__lte'))
    ) {
      rangeFltrs.push(filter);
    } else if (getSlugParamFromFilter(filter.id)) {
      if (
        filter.id === 'page' &&
        (filter.value === '1' || filter.value === (1 as any) || !filter.value)
      ) {
        return;
      }
      forPath.push(filter);
      if (filter.id === 'make') makePosition = 'path';
      if (filter.id === 'model') modelPosition = 'path';
      if (filter.id === 'variant-type') variantPosition = 'path';
    } else if (
      filter.id === 'area' ||
      filter.id === 'region' ||
      (filter.id === 'dealership-id' && root === 'dealerships')
    ) {
      newPath.push(`/${filter.value}`);
      return;
    } else {
      forQuery.push(filter);
    }
  });

  //model path value (model name) can not make a valid model id without make data
  if (modelPosition === 'path' && makePosition !== 'path') {
    const modelFilterIndex = forPath.findIndex(fltr => fltr.id === 'model');
    const removedModel = forPath.splice(modelFilterIndex, 1);

    if (makePosition === 'query') {
      forQuery.push(removedModel[0]);
    }
  }

  //variant path value (variant name) can not make a valid variant id without make and model data
  if (variantPosition === 'path' && (makePosition !== 'path' || modelPosition !== 'path')) {
    const trimFilterIndex = forPath.findIndex(fltr => fltr.id === 'variant-type');
    const removedVariant = forPath.splice(trimFilterIndex, 1);

    if (makePosition !== undefined && modelPosition !== undefined) {
      forQuery.push(removedVariant[0]);
    }
  }

  //range filters are grouped and sent to path or query together
  const rangeFltrsProcessed: string[] = [];
  rangeFltrs.forEach(rangeFltr => {
    const fltrBaseName = rangeFltr.id.split('__')[0];

    if (!rangeFltrsProcessed.includes(fltrBaseName)) {
      const partnerFltr = rangeFltrs.find(
        fltr => fltr.id === getRangeFilterPartnerId(rangeFltr.id),
      );

      //some filters go in path, some in query
      if (getSlugParamFromFilter(rangeFltr.id)) {
        const groupedFltr = {
          ...rangeFltr,
          value: getRangeVal(rangeFltr, partnerFltr),
        };

        forPath.push(groupedFltr);
      } else {
        forQuery.push(rangeFltr);
        if (partnerFltr) forQuery.push(partnerFltr);
      }

      rangeFltrsProcessed.push(fltrBaseName);
    }
  });

  //order & build path
  SLUG_PARAM_KEYS.forEach(slugParam => {
    const filterKeys = slugParamMapping[slugParam];
    const filter = forPath.find(fltr => fltr.id === filterKeys[0]);
    if (filter) {
      newPath.push(`/${getSlugParamFromFilter(filter.id)}/${getSlugParamValueFromFilter(filter)}`);
    }
  });

  //build query
  const queryString = createQueryStrFromFilters(forQuery);

  return `${newPath.join('')}${queryString ? `?${queryString}` : ''}`;
}

export { createPathFromFilters };
